<template>
    <div class="customer-search-wrap" v-click-outside="hideSuggestions">
        <div class="customer-search">
            <form class="search-form" @submit.prevent="search">
                <div class="customer-search-lg">
                    <input
                        type="text"
                        name="query"
                        class="form-control search-input"
                        autocomplete="off"
                        v-model="form.query"
                        :placeholder="$trans('storefront::layout.search_for_customer')"
                        @focus="showExistingSuggestions"
                        @keydown.esc="hideSuggestions"
                        @keydown.down="nextSuggestion"
                        @keydown.up="prevSuggestion"
                    />

                    <div class="customer-search-right" @focusin="hideSuggestions">
                        <button
                            type="submit"
                            class="btn btn-primary btn-search"
                        >
                            <i class="las la-search"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <div class="customer-search-sm-form">
            <form class="search-form" @submit.prevent="search">
                <div class="btn-close" @click="showSuggestions = false">
                    <i class="las la-arrow-left"></i>
                </div>

                <input
                    type="text"
                    name="query"
                    class="form-control search-input-sm"
                    autocomplete="off"
                    :placeholder="$trans('storefront::layout.search_for_customers')"
                    :value="form.query"
                    @input="form.query = $event.target.value"
                    @focus="showExistingSuggestions"
                    @keydown.esc="hideSuggestions"
                    @keydown.down="nextSuggestion"
                    @keydown.up="prevSuggestion"
                />

                <button type="submit" class="btn btn-search">
                    <i class="las la-search"></i>
                </button>
            </form>
        </div>

        <div
            class="search-suggestions overflow-hidden"
            v-if="shouldShowSuggestions"
        >
            <div
                class="search-suggestions-inner custom-scrollbar"
                ref="searchSuggestionsInner"
            >
                <div class="products-suggestion">
                    <h6 class="title">
                        {{ $trans("storefront::layout.customer_suggestions") }}
                    </h6>

                    <ul class="list-inline product-suggestion-list">
                        <li
                            v-for="customer in suggestions.customers"
                            :key="customer.id"
                            class="list-item"
                            :class="{ active: isActiveSuggestion(customer) }"
                            :ref="customer.id"
                            @mouseover="changeActiveSuggestion(customer)"
                            @mouseleave="clearActiveSuggestion"
                        >
                            <a
                                :href="'#'"
                                class="single-item"
                                @click.prevent="selectCustomer(customer)"
                            >
                                <div class="product-info">
                                    <div class="product-info-top">
                                        <h6
                                            class="product-name"
                                            v-html="customer.first_name + ' ' + customer.last_name" 
                                        ></h6>
                                        <p class="customer-company" v-html="customer.company_name"></p>
                                    </div>
                                    <div
                                        class="product-price"
                                        v-html="'TVA: '+customer.tax_no"
                                    ></div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { throttle } from "lodash";

export default {
    props: ["mostSearchedKeywords", "isMostSearchedKeywordsEnabled", "initialQuery" , 'customerTaxNoSelected'],

    data() {
        return {
            activeSuggestion: null,
            showSuggestions: false,
            form: {
                query: this.initialQuery,
            },
            suggestions: {
                customers: [],
                remaining: 0,
            },
        };
    },

    computed: {
        shouldShowSuggestions() {
            return this.showSuggestions && this.hasAnySuggestion;
        },

        moreResultsUrl() {
            return route("customers.index", { query: this.form.query });
        },

        hasAnySuggestion() {
            return this.suggestions.customers.length !== 0;
        },

        allSuggestions() {
            return [...this.suggestions.customers];
        },

        firstSuggestion() {
            return this.allSuggestions[0];
        },

        lastSuggestion() {
            return this.allSuggestions[this.allSuggestions.length - 1];
        },
    },

    watch: {
        "form.query": throttle(function (newQuery) {
            if (newQuery === "") {
                this.clearSuggestions();
            } else {
                this.showSuggestions = true;
                this.fetchSuggestions();
            }
        }, 600),
    },

    methods: {
        fetchSuggestions() {
            axios
                .get(route("suggestions.customers", this.form))
                .then(({ data }) => {
                    this.suggestions.customers = data.customers;
                    this.suggestions.remaining = data.remaining;

                    this.clearActiveSuggestion();
                    this.resetSuggestionScrollBar();
                });
        },

        search() {
            if (!this.form.query) return;
            this.$emit('customer-tax-no-selected', this.form.query); 
            this.hideSuggestions();
        },

        showExistingSuggestions() {
            if (this.form.query !== "") this.showSuggestions = true;
        },

        clearSuggestions() {
            this.suggestions.customers = [];
        },

        hideSuggestions() {
            this.showSuggestions = false;
            this.clearActiveSuggestion();
            return false;
        },

        selectCustomer(customer) {
            this.$emit('customer-tax-no-selected', customer.tax_no); 
            this.hideSuggestions();
        },

        isActiveSuggestion(suggestion) {
            return this.activeSuggestion && this.activeSuggestion.tax_no === suggestion.tax_no;
        },

        changeActiveSuggestion(suggestion) {
            this.activeSuggestion = suggestion;
        },

        clearActiveSuggestion() {
            this.activeSuggestion = null;
        },

        nextSuggestion() {
            if (!this.hasAnySuggestion) return;
            this.activeSuggestion = this.allSuggestions[this.nextSuggestionIndex()];
            if (!this.activeSuggestion) this.activeSuggestion = this.firstSuggestion;
            this.adjustSuggestionScrollBar();
        },

        prevSuggestion() {
            if (!this.hasAnySuggestion) return;
            if (this.prevSuggestionIndex() === -1) {
                this.clearActiveSuggestion();
                return;
            }
            this.activeSuggestion = this.allSuggestions[this.prevSuggestionIndex()];
            if (!this.activeSuggestion) this.activeSuggestion = this.lastSuggestion;
            this.adjustSuggestionScrollBar();
        },

        nextSuggestionIndex() {
            return this.currentSuggestionIndex() + 1;
        },

        prevSuggestionIndex() {
            return this.currentSuggestionIndex() - 1;
        },

        currentSuggestionIndex() {
            return this.allSuggestions.indexOf(this.activeSuggestion);
        },

        adjustSuggestionScrollBar() {
            this.$refs.searchSuggestionsInner.scrollTop =
                this.$refs[this.activeSuggestion.tax_no][0].offsetTop - 200;
        },

        resetSuggestionScrollBar() {
            if (this.$refs.searchSuggestionsInner !== undefined) {
                this.$refs.searchSuggestionsInner.scrollTop = 0;
            }
        },
    },
};
</script>
