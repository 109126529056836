<template>
    <div class="list-product-card">
        <div class="list-product-card-inner">
            <div class="product-card-left position-relative">
                <a :href="productUrl" class="product-image">
                    <img :src="baseImage" :class="{ 'image-placeholder': !hasBaseImage }" :alt="product.name" />
                </a>

                <ul class="list-inline product-badge">
                    <li class="badge badge-danger" v-if="item.is_out_of_stock">
                        {{ $trans("storefront::product_card.out_of_stock") }}
                    </li>

                    <li class="badge badge-primary" v-else-if="product.is_new">
                        {{ $trans("storefront::product_card.new") }}
                    </li>

                    <li class="badge badge-success" v-if="item.has_percentage_special_price">
                        -{{ item.special_price_percent }}%
                    </li>
                </ul>
            </div>

            <div class="product-card-right">
                <a :href="productUrl" class="product-name">
                    <h6>{{ product.name }} | #{{ product.id }}</h6>
                </a>
                <!-- <button
                        class="btn btn-wishlist"
                        :class="{ added: inWishlist }"
                        @click="syncWishlist"
                    >
                        <i
                            class="la-heart"
                            :class="inWishlist ? 'las' : 'lar'"
                        ></i>
                    </button> -->

                <div class="clearfix"></div>

                <!-- <product-rating
                    :ratingPercent="product.rating_percent"
                    :reviewCount="product.reviews.length"
                >
                </product-rating> -->


                <table class="table table-striped table-sm table_product_details">

                    <tbody>
                        <tr>
                            <td>{{ $trans("storefront::product_card.packing") }}</td>
                            <td v-html="(item.unit_value + item.unit) || '-'"></td>
                        </tr>
                        <tr>
                            <td>{{ $trans("storefront::product_card.price_per_unit") }}</td>
                            <td v-html="(item.price.amount / item.unit_value).toFixed(2)"></td>
                        </tr>
                        <tr>
                            <td>{{ $trans("storefront::product_card.expried") }}</td>
                            <td class="text-dange" v-html="item.expried_date || '-'"></td>
                        </tr>
                        <tr>
                            <td>{{ $trans("storefront::product_card.price") }}</td>
                            <td class="product-price" v-html="item.formatted_price"></td>
                        </tr>
                    </tbody>
                </table>

                <div class="d-flex align-items-center">
                <template v-if="cartItem?.id">
                    <!-- Quantity Select -->
                    <select v-model="cartItem.qty" class="form-control input-number input-quantity mr-2"
                        @change="changeQuantity(Number(cartItem.qty))">
                        <option v-for="n in maxQuantity(cartItem)" :key="n" :value="n">
                            {{ n }}
                        </option>
                    </select>

                    <!-- Update Quantity Button -->
                    <button type="button" class="btn btn-sm btn-primary mr-2"
                        :disabled="isQtyIncreaseDisabled(cartItem)" @click="updateQuantity(cartItem.qty + 1)"
                        style="width: 80px;">
                        <span v-if="addingToCart" class="btn-loading">&nbsp;</span>
                        <span v-else>
                            <i class="las la-cart-arrow-down"></i>
                        </span>
                    </button>

                    <!-- Delete Button -->
                    <button type="button" class="btn btn-sm btn-danger" @click="updateQuantity(0)"
                        style="width: 40px; padding: 0.25rem 0.5rem; font-size: 0.8rem; color:white">
                        <span>
                            <i class="las la-trash white"></i>
                        </span>
                    </button>
                </template>
                <template v-else>
                    <button v-if="hasNoOption || item.is_out_of_stock" class="btn btn-sm btn-block"
                        :class="{ 'btn-primary': !item.is_out_of_stock, 'btn-danger': item.is_out_of_stock }"
                        :disabled="item.is_out_of_stock" @click="add">
                        <i class="las la-cart-arrow-down"></i>
                        <span v-if="addingToCart" class="btn-loading"></span>
                        <span v-else>
                            <span v-if="item.is_out_of_stock">
                                {{ $trans("storefront::product_card.out_of_stock") }}
                            </span>
                            <span v-else>

                                {{ $trans("storefront::product_card.add_to_cart") }}
                            </span>
                        </span>
                    </button>

                    <a v-else :href="productUrl" class="btn btn-primary btn-add-to-cart">
                        <i class="las la-eye"></i>
                        {{ $trans("storefront::product_card.view_options") }}
                    </a>
                </template>
            </div>
                <div class="product-card-actions">

<!-- 
                    <button
                        class="btn btn-compare"
                        :class="{ added: inCompareList }"
                        @click="syncCompareList"
                    >
                        <i class="las la-random"></i>
                        {{ $trans("storefront::product_card.compare") }}
                    </button> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductCardMixin from "../../../mixins/ProductCardMixin";

export default {
    mixins: [ProductCardMixin],

    props: ["product"],

    computed: {
        item() {
            return {
                ...(this.product.variant ? this.product.variant : this.product),
            };
        },
    },
};
</script>
